* {
  box-sizing: border-box;
}

html, body {
  margin: 0;
  padding: 0;
}

h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, cite, code, del, dfn, em, img, q, s, samp, small, strike, strong, sub, sup, tt, var, dd, dl, dt, li, ol, ul, fieldset, form, label, legend, button, table, caption, tbody, tfoot, thead, tr, th, td {
  border: none;
  margin: 0;
  padding: 0;
  font-family: inherit;
  font-size: 100%;
  font-style: normal;
  font-weight: normal;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

ol, ul {
  list-style: none;
}

q:before, q:after, blockquote:before, blockquote:after {
  content: "";
}

html {
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  font-size: 100%;
}

article, aside, details, figcaption, figure, footer, header, hgroup, nav, section {
  display: block;
}

figure {
  margin: 0;
}

audio, canvas, video {
  zoom: 1;
  display: inline;
}

audio:not([controls]) {
  display: none;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sup {
  top: -.5em;
}

sub {
  bottom: -.25em;
}

img {
  -ms-interpolation-mode: bicubic;
  border: none;
}

button, input, select, textarea {
  vertical-align: baseline;
  outline: none;
  margin: 0;
  font-size: 100%;
}

button, input {
  line-height: normal;
  overflow: visible;
}

button::-moz-focus-inner, input::-moz-focus-inner {
  border: none;
  padding: 0;
}

button, input[type="button"], input[type="reset"], input[type="submit"] {
  cursor: pointer;
  -webkit-appearance: button;
}

input[type="search"] {
  -webkit-appearance: textfield;
  box-sizing: content-box;
}

input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

textarea {
  vertical-align: top;
  overflow: auto;
}

@font-face {
  font-family: Poppins;
  src: url("Poppins-Light.fc022b91.ttf");
  font-style: normal;
  font-weight: 200;
}

@font-face {
  font-family: Poppins;
  src: url("Poppins-Regular.0563f851.ttf");
  font-style: normal;
  font-weight: 300;
}

@font-face {
  font-family: Poppins;
  src: url("Poppins-Medium.7127a3f3.ttf");
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: Poppins;
  src: url("Poppins-SemiBold.52617979.ttf");
  font-style: normal;
  font-weight: 500;
}

@font-face {
  font-family: Poppins;
  src: url("Poppins-Bold.a36deb26.ttf");
  font-style: normal;
  font-weight: 600;
}

@font-face {
  font-family: Inconsolata;
  src: url("Inconsolata-Regular.2dc1d63a.ttf");
  font-style: normal;
  font-weight: 300;
}

@font-face {
  font-family: Inconsolata;
  src: url("Inconsolata-Bold.9a8d073c.ttf");
  font-style: normal;
  font-weight: 600;
}

html {
  -webkit-font-smoothing: antialias;
  font-size: 62.5%;
  text-rendering: optimizelegibility !important;
}

a {
  color: inherit;
}

.intro {
  margin-bottom: 6rem;
}

@media (min-width: 1441px) {
  .intro {
    margin-bottom: 12rem;
  }
}

.intro__tagline {
  letter-spacing: -.025em;
  max-width: 1000px;
  margin-bottom: .6rem;
  font-family: Poppins, sans-serif;
  font-size: 6.8rem;
  font-weight: 700;
  line-height: 1em;
}

@media (max-width: 736px) {
  .intro__tagline {
    font-size: 6rem;
  }
}

@media (min-width: 1441px) {
  .intro__tagline {
    max-width: 1500px;
    margin-bottom: 1.5rem;
    font-size: 12rem;
  }
}

.intro__name {
  letter-spacing: -.025em;
  font-family: Poppins, sans-serif;
  font-size: 4.2rem;
  font-weight: 200;
  line-height: 1em;
}

@media (max-width: 736px) {
  .intro__name {
    font-size: 3.3rem;
  }
}

@media (min-width: 1441px) {
  .intro__name {
    font-size: 6rem;
  }
}

.about {
  max-width: 750px;
  margin: 14rem auto 13rem;
  font-family: Inconsolata, monospace;
  font-size: 2.25rem;
  line-height: 1.5em;
}

@media (max-width: 736px) {
  .about {
    margin: 1.5rem 2rem 3rem;
    font-size: 2.2rem;
  }
}

@media (min-width: 1441px) {
  .about {
    max-width: 750px;
    margin-top: 20rem;
    font-size: 2.5rem;
  }
}

.about a.hover {
  color: #d40b3b;
}

.about__para {
  margin-bottom: 1em;
}

@media (max-width: 736px) {
  .about__contact {
    display: block;
  }
}

.about__contact-wrapper {
  justify-content: space-between;
  display: flex;
}

@media (max-width: 736px) {
  .about__contact-wrapper {
    display: block;
  }
}

.selected-work {
  text-align: center;
  cursor: pointer;
  box-sizing: content-box;
  padding: 0 10rem 10rem;
  position: relative;
  color: #d40b3b !important;
}

@media (max-width: 736px) {
  .selected-work {
    padding: 0 2.5rem 5rem;
  }
}

@media (min-width: 1441px) {
  .selected-work {
    padding: 10rem 15rem 16rem;
  }
}

.border {
  background: #d40b3b;
  position: absolute;
}

.border--top, .border--bottom {
  width: 100%;
  height: 35px;
  top: 0;
  left: 0;
}

@media (max-width: 736px) {
  .border--top, .border--bottom {
    height: 20px;
  }
}

@media (min-width: 1441px) {
  .border--top, .border--bottom {
    height: 42px;
  }
}

.border--bottom {
  top: initial;
  bottom: 0;
}

.border--left, .border--right {
  height: 100%;
  width: 35px;
  top: 0;
  left: 0;
}

@media (max-width: 736px) {
  .border--left, .border--right {
    width: 20px;
  }
}

@media (min-width: 1441px) {
  .border--left, .border--right {
    width: 42px;
  }
}

.border--right {
  left: initial;
  right: 0;
}

.selected-work * {
  -webkit-user-select: none;
}

.selected-work__title {
  letter-spacing: .15em;
  text-transform: uppercase;
  cursor: default;
  margin: 5rem 0 7rem;
  font-family: Poppins, sans-serif;
  font-size: 1.5rem;
  font-weight: 700;
  display: inline-block;
}

@media (min-width: 1441px) {
  .selected-work__title {
    margin: 7rem 0 11rem;
    font-size: 1.6rem;
  }
}

.selected-work__title:first-child {
  margin-top: 0;
}

.selected-work a:not(.body-link) {
  text-decoration: none;
  display: inline-block;
  -webkit-user-select: none !important;
}

@media (max-width: 736px) {
  .selected-work__list {
    padding: 5rem 3.5rem 3.5rem;
  }
}

.selected-work__list-item {
  transform-origin: 50%;
  margin-bottom: 7em;
  display: inline-block;
}

@media (min-width: 1441px) {
  .selected-work__list-item {
    margin-bottom: 10em;
  }
}

.selected-work__list-item:last-child {
  margin-bottom: 4rem;
}

.selected-work__list-item-title {
  letter-spacing: -.025em;
  -webkit-user-select: none;
  margin-bottom: .8rem;
  font-family: Poppins, sans-serif;
  font-size: 4.2rem;
  font-weight: 600;
  line-height: 1em;
}

@media (max-width: 736px) {
  .selected-work__list-item-title {
    font-size: 3rem;
  }
}

@media (min-width: 1441px) {
  .selected-work__list-item-title {
    margin-bottom: 1.5rem;
    font-size: 5rem;
  }
}

.selected-work__span {
  max-width: 90ch;
  font-family: Inconsolata, monospace;
  font-size: 1.65rem;
  line-height: 1.45em;
  display: block;
}

.selected-work canvas {
  width: 100%;
  height: 100%;
  background: #0000ff1a;
  position: absolute;
  top: 0;
  left: 0;
}

.emoji {
  pointer-events: none;
  -webkit-user-select: none;
  font-size: 3.2rem;
  line-height: 1em;
}

@media (min-width: 1441px) {
  .emoji {
    font-size: 4rem;
  }
}

.emoji--bomb {
  z-index: 1111;
  margin-top: -22px;
  margin-left: -15px;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
}

@media (max-width: 736px) {
  .emoji--bomb {
    display: none !important;
  }
}

.emoji--bomb span {
  transition: transform 70ms;
  display: inline-block;
}

.emoji--bomb:after {
  content: "";
  filter: blur(5px);
  width: 70%;
  height: 8px;
  opacity: .25;
  transform-origin: center;
  background: #000;
  border-radius: 100px;
  transition: transform 70ms, opacity 70ms;
  position: absolute;
  bottom: -10px;
  left: 1px;
  box-shadow: 0 0 5px #00000080;
}

.emoji--bomb.touched span {
  transform: translateY(10px);
}

.emoji--bomb.touched:after {
  opacity: .187;
  transform: scale(.7);
}

.emoji--fire {
  position: absolute;
}

.selected-work:not(.selected-work--item-hover):hover .emoji--bomb {
  display: block;
}

@keyframes sprite {
  from {
    background-position: 0;
  }

  to {
    background-position: -600px;
  }
}

.explosion {
  width: 30px;
  height: 30px;
  z-index: 2;
  background-image: url("burn.877f0492.gif");
  background-size: contain;
  position: absolute;
}

.explosion__boom {
  width: 39px;
  height: 75px;
  background-image: url("spritesheet.45060a95.gif");
  background-repeat: no-repeat;
  background-size: auto 100%;
  animation: sprite .8s steps(15, end) forwards;
  position: absolute;
  bottom: 0;
  left: -6px;
}

.case-study {
  background: #fff;
  margin-top: 33vw;
}

.case-study__header {
  z-index: -1;
  width: 100%;
  height: 33vw;
  margin-bottom: 14rem;
  display: block;
  position: fixed;
  top: 0;
  overflow: hidden;
}

.case-study__header video {
  width: 100%;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

@media (max-width: 736px) {
  .case-study__header {
    margin-bottom: 8rem;
  }
}

.case-study {
  padding-top: 3rem;
}

.case-study__title {
  letter-spacing: -.025em;
  color: #d40b3b;
  text-align: center;
  margin-top: 2.6em;
  margin-bottom: 1em;
  font-family: Poppins, sans-serif;
  font-size: 4.2rem;
  font-weight: 600;
  line-height: 1em;
}

@media (max-width: 736px) {
  .case-study__title {
    margin-left: 2rem;
    margin-right: 2rem;
    font-size: 3rem;
  }
}

@media (min-width: 1441px) {
  .case-study__title {
    font-size: 5rem;
  }
}

.case-study__para {
  max-width: 650px;
  margin: 0 auto 1em;
  font-family: Inconsolata, monospace;
  font-size: 2.25rem;
  line-height: 1.5em;
}

@media (max-width: 736px) {
  .case-study__para {
    margin: 1.5rem 2rem 3rem;
    font-size: 2.2rem;
  }
}

@media (min-width: 1441px) {
  .case-study__para {
    max-width: 750px;
    font-size: 2.5rem;
  }
}

figure {
  text-align: center;
  width: 660px;
  margin: 6rem auto;
  padding: 0 5px;
  font-size: 0;
  position: relative;
}

@media (max-width: 736px) {
  figure {
    width: 100%;
    padding-left: calc(2rem - 5px);
    padding-right: calc(2rem - 5px);
  }
}

@media (min-width: 1441px) {
  figure {
    width: 750px;
  }
}

.figure--full {
  width: 100%;
  padding: 0 40px;
}

@media (max-width: 736px) {
  .figure--full {
    padding-left: calc(2rem - 5px);
    padding-right: calc(2rem - 5px);
  }
}

figcaption {
  text-align: left;
  color: #d40b3b;
  max-width: 520px;
  padding: 0 5px;
  font-family: Inconsolata, monospace;
  font-size: 1.5rem;
}

figure img, figure video {
  padding: 5px;
}

.img--small {
  width: 25%;
  height: auto;
}

@media (max-width: 736px) {
  .img--small {
    width: 50%;
  }
}

.img--medium {
  width: 33.33%;
  height: auto;
}

@media (max-width: 736px) {
  .img--medium {
    width: 100%;
  }
}

.img--large {
  width: 50%;
  height: auto;
}

@media (max-width: 736px) {
  .img--large {
    width: 100%;
  }
}

.img--xl {
  width: 100%;
  height: auto;
  padding-left: 0;
  padding-right: 0;
}

figure audio {
  height: 50px;
  width: 100%;
  margin-bottom: 5px;
}

.text--red {
  color: #d40b3b;
}

i {
  text-transform: uppercase;
  vertical-align: baseline;
  letter-spacing: .17em;
  color: #d40b3b;
  font-size: .77em;
  font-style: normal;
}

/*# sourceMappingURL=index.fe38d48a.css.map */
